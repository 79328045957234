class UploadedFile {
  id: string | null;
  name: string;
  mime_type: string | null;
  size: string;
  full_url: string;
  thumb_url: string;

  constructor(file) {
    this.id = file.id || null;
    this.name = file.name;
    this.mime_type = file.mime_type || file.type || null;
    this.size = file.size;
    this.full_url = file.full_url || false;
    this.thumb_url = file.thumb_url || false;
  }

  get icon() {
    let mimeType = String(this.mime_type);

    if (mimeType.indexOf("image") === 0) return "file-image";
    else if (mimeType.indexOf("video") === 0) return "file-video";
    else if (mimeType.includes("pdf")) return "file-pdf";
    else if (mimeType.includes("zip") || mimeType.includes("rar")) return "file-archive";
    else if (mimeType.includes("word")) return "file-word";
    else if (mimeType.includes("xls") || mimeType.includes("excel")) return "file-excel";

    return "file-alt";
  }

  get filesize() {
    let fileSizeInBytes = this.size || 0;
    let i = -1;
    let byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];

    do {
      fileSizeInBytes = fileSizeInBytes / 1024;
      i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  }
}

const parseFile = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader.result) {
        file.full_url = reader.result;
        file.thumb_url = reader.result;
        resolve(file);
      }
    };

    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file);
  });
};

export const useFileInput = () => {
  return {
    parseFile,
    UploadedFile,
  };
};
